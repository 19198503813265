import React, { useEffect } from 'react';

function RedirectComponent() {
  useEffect(() => {
    // Set the Google Drive link you want to redirect to
    const googleDriveLink = 'https://drive.google.com/file/d/1BJyTYYZf_aigRnoWvMWFwGB3N7VZEwQY/view?usp=sharing';

    // Perform the redirection
    window.location.href = googleDriveLink;
  }, []);

  return (
    <div>
      <p>Redirecting to Resume on Google Drive...</p>
    </div>
  );
}

export default RedirectComponent;
